<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row
        class="mt-2"
      >
        <b-col cols="12">
          <span>Bật tính năng kiểm kê tài sản khi ký & thanh lý hợp đồng</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.enableContractAssetCheck"
              name="enableContractAssetCheck"
              :value="true"
              class="custom-control-primary"
            >
              Bật
            </b-form-radio>

            <b-form-radio
              v-model="data.enableContractAssetCheck"
              name="enableContractAssetCheck"
              :value="false"
              class="custom-control-primary"
            >
              Tắt
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>Mẫu hợp đồng</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.applyNewContractTemplate"
              name="applyNewContractTemplate"
              :value="true"
              class="custom-control-primary"
            >
              Mẫu mới
            </b-form-radio>

            <b-form-radio
              v-model="data.applyNewContractTemplate"
              name="applyNewContractTemplate"
              :value="false"
              class="custom-control-primary"
            >
              Mẫu cũ
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>Bật tính năng ký hợp đồng online</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.enableSigningContractOnline"
              name="enableSigningContractOnline"
              :value="true"
              class="custom-control-primary"
            >
              Bật
            </b-form-radio>

            <b-form-radio
              v-model="data.enableSigningContractOnline"
              name="enableSigningContractOnline"
              :value="false"
              class="custom-control-primary"
            >
              Tắt
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-2"
      >
        <b-col cols="12">
          <span>Bật tính năng cài đặt ngày thanh toán</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.allowContractPaymentDayConfig"
              name="allowContractPaymentDayConfig"
              :value="true"
              class="custom-control-primary"
            >
              Bật
            </b-form-radio>

            <b-form-radio
              v-model="data.allowContractPaymentDayConfig"
              name="allowContractPaymentDayConfig"
              :value="false"
              class="custom-control-primary"
            >
              Tắt
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="contractExpireDay"
            rules="required|between:0,100"
          >
            <number-input
              v-model="data.contractExpireDay"
              label="Hiển thị trạng thái sắp hết hạn khi hợp đồng còn X ngày"
              :default-value="data.contractExpireDay"
              :state="getValidationState(validationContext)"
              :error="validationContext.errors[0]"
            />
          </validation-provider>

        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="contractNotifyDays"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                Nhận thông báo quá hạn hợp đồng khi còn X ngày (có thể nhiều ngày, cách nhau bằng dấu phẩy)
              </template>
              <b-form-input
                id="name"
                v-model="data.contractNotifyDays"
                placeholder="0,1,5,10,15,20,25,30"
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>

        </b-col>
      </b-row>

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        Lưu
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BFormRadio, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import { required, between } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import { updateUserConfiguration } from '@/auth/utils';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    NumberInput,
    BFormRadio,
    BFormGroup,
    BFormInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        enableSigningContractOnline: false,
        enableContractAssetCheck: false,
        contractExpireDay: 30,
        contractNotifyDays: '',
        allowContractPaymentDayConfig: false,
        applyNewContractTemplate: false,
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      isTingTong,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getUserConfiguration()
        .then(response => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append('enableSigningContractOnline', this.data.enableSigningContractOnline);
      data.append('enableContractAssetCheck', this.data.enableContractAssetCheck);
      data.append('contractExpireDay', this.data.contractExpireDay);
      data.append('contractNotifyDays', this.data.contractNotifyDays);
      data.append('allowContractPaymentDayConfig', this.data.allowContractPaymentDayConfig);
      data.append('applyNewContractTemplate', this.data.applyNewContractTemplate);

      useJwt.updateUserConfiguration(data).then(response => {
        updateUserConfiguration(response.data);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'UserIcon',
            variant: 'success',
            text: `Thông tin đã được cập nhật thành công`,
          },
        });
      })
        .catch(error => {
          if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          }
        });
    },
  },
};
</script>
