import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    tingeeVerifyOCBInfo(ctx, data) {
      return useJwt.tingeeVerifyOCBInfo(data).then(response => response);
    },
    tingeeVerifyOCBInfoWithOTP(ctx, data) {
      return useJwt.tingeeVerifyOCBInfoWithOtp(data).then(response => response);
    },
  },
};
