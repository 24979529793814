import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import tingeeStoreModule from './tingeeStoreModule';

export default function useTingeeOcbModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'tingee';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tingeeStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };
  const step = ref(1);

  const resetModal = () => {
    resetItemLocal();
    itemLocal.value.customerName = '';
    itemLocal.value.mobilePhone = '';
    itemLocal.value.email = '';
    itemLocal.value.accountNumber = '';
    itemLocal.value.merchantName = '';
    itemLocal.value.merchantAddress = '';
    itemLocal.value.identificationNumber = '';
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }
    isSubmitting.value = true;
    if (step.value === 1) {
      const data = {
        customerName: itemLocal.value.customerName,
        mobilePhone: itemLocal.value.mobilePhone,
        email: itemLocal.value.email,
        accountNumber: itemLocal.value.accountNumber,
        merchantName: itemLocal.value.merchantName,
        merchantAddress: itemLocal.value.merchantName,
        identificationNumber: itemLocal.value.identificationNumber,
      };

      store
        .dispatch('tingee/tingeeVerifyOCBInfo', data)
        .then(response => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
          itemLocal.value.ocbResponse = response.data;
          step.value = 2;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      const data = { otpCode: itemLocal.value.otpCode, bankRefNo: itemLocal.value.ocbResponse.bankRefNo };
      store
        .dispatch('tingee/tingeeVerifyOCBInfoWithOTP', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    step,
  };
}
