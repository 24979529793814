<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row class="mt-1">
        <b-col>

          <validation-provider
            #default="validationContext"
            name="salePath"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                Đường dẫn công khai <span class="text-danger">(*)</span>
              </template>
              <b-input-group
                :prepend="baseUrl"
                class="input-group-merge"
              >
                <b-form-input
                  id="salePath"
                  v-model="salePath"
                  placeholder="ten-thuong-hieu"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="preview"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                    />
                    Xem thử
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>

        </b-col>
      </b-row>

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        Lưu
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BButton, BInputGroupAppend,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import toast from '@/utils/toast';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // validation
      required,
      salePath: null,
      baseUrl: process.env.VUE_APP_PROVIDER === 'resident' ? 'https://app.resident.vn/sale/' : 'https://app.tingtong.vn/sale/',
    };
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const toastification = toast();

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      toastification,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getUserConfiguration()
        .then(response => {
          this.salePath = response.data.salePath;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },
    onSubmit() {
      useJwt.updateUserConfiguration({ salePath: this.salePath }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'UserIcon',
            variant: 'success',
            text: `Thông tin đã được cập nhật thành công`,
          },
        });
      }).catch(error => {
        if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
          this.$refs.refFormObserver.setErrors(error.response.data.errors);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      });
    },
    preview() {
      if (this.salePath) {
        window.open(process.env.VUE_APP_PROVIDER === 'resident' ? `https://app.resident.vn/sale/${this.salePath}` : `https://app.tingtong.vn/sale/${this.salePath}`, '_blank', 'noreferrer');
      }
    },
  },
};
</script>
