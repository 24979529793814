<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Resident kết hợp cùng <b-link
              href="https://healthcarenow.larksuite.com/file/XZ1WboF1SoCYmexcbA3uw5qwsUc"
              target="_blank"
              class="text-danger"
            >Cổng báo có tiền về Tingee by HENO</b-link> để nhận thông tin giao dịch CHÍNH THỨC từ ngân hàng, giúp khách hàng yên tâm về bảo mật và kết nối:</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <app-collapse
      accordion
      type="border"
    >

      <app-collapse-item title="">
        <template slot="header">
          <span>1. OCB (Giải pháp báo có <b-link class="text-danger">Tingee</b-link> 🚀)</span>
        </template>
        <tingee-ocb />

      </app-collapse-item>
      <!-- <app-collapse-item title="">
        <template slot="header">
          <span>2. TPBank (Giải pháp báo có <b-link class="text-danger">Tingee</b-link> 🚀)</span>
        </template>
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              We are launching soon 🚀
            </h2>
            <p class="mb-3">
              We're creating something awesome. Please subscribe to get notified when it's ready!
            </p>

            <b-img
              fluid
              src="@/assets/images/pages/coming-soon.svg"
              alt="Coming soon page"
            />
          </div>
        </div> -->
      <!-- <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
            >
              <feather-icon
                icon="CreditCardIcon"
                class="mr-50"
              />
              <span class="align-middle">Kết nối với TPBank</span>
            </b-button>
          </b-col>

        </b-row> -->
      <!-- </app-collapse-item> -->

      <!-- <app-collapse-item title="3. Casso (Chậm hơn Tingee từ 5 - 20 phút)">
        <template slot="header">
          <span>3. Casso <small class="text-muted">(Chậm hơn Tingee từ 5 - 20 phút)</small></span>
        </template>
        <b-row class="mt-1">
          <b-col cols="12">

            <b-form-group>
              <template v-slot:label>
                Webhook URL
              </template>
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="cassoWebhook"
                  v-model="cassoWebhook"
                  placeholder=""
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    v-clipboard:copy="cassoWebhook"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      class="mr-50"
                    />
                    Sao chép
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template v-slot:label>
                Secret Key
              </template>
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="cassoSecretKey"
                  placeholder=""
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    v-clipboard:copy="cassoSecretKey"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      class="mr-50"
                    />
                    Sao chép
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </app-collapse-item> -->

    </app-collapse>

  </div>

</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import toast from '@/utils/toast';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import TingeeOcb from './tingee/TingeeOcb.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BLink,
    TingeeOcb,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      cassoWebhook: '',
      cassoSecretKey: '',
    };
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const toastification = toast();

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      toastification,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getUserConfiguration()
        .then(response => {
          this.cassoWebhook = `https://api.resident.vn/v1/casso/${response.data.cassoWebhook}`;
          this.cassoSecretKey = response.data.cassoSecretKey;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    preview() {

    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
