<template>
  <b-modal
    id="modal-tingee-ocb"
    ref="refModal"
    title="Tài khoản OCB"
    :ok-title="isSubmitting ? 'Đang kiểm tra' : step === 1 ? 'Lấy mã OTP' : 'Liên kết'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Đăng ký tài khoản ngân hàng OCB
              </div>
            </div>
          </b-col>
          <b-col>
            <b-alert
              show
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="StarIcon"
                />
                <span class="ml-25">Để kết nối dịch vụ bạn cần có tài khoản ngân hàng OCB còn hoạt động bình thường. Nếu chưa có tài khoản, hãy QUÉT MÃ QRCODE bên dưới để bắt đầu tạo tài khoản OCB.</span>
              </div>
            </b-alert>
            <b-img
              :src="require('@/assets/images/qrcode/tingee_ocb.png')"
              width="240px"
              height="240px"
            />
          </b-col>
        </b-row>

        <div
          class="mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  2. Thông tin đăng ký
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25">Lưu ý: <strong>Thông tin giấy tờ tùy thân</strong> và <strong>số điện thoại</strong> phải TRÙNG KHỚP với thông tin mà bạn đã đăng ký tài khoản thanh toán tại OCB:</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="customerName"
                rules="required"
              >
                <b-form-group
                  label="Họ và tên (Tiếng Việt không dấu)"
                >
                  <template
                    v-slot:label
                  >
                    Họ và tên (Tiếng Việt không dấu)
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="customerName"
                    v-model="itemLocal.customerName"
                    placeholder="Nguyen Nam Phong"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="accountNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Số tài khoản tại OCB
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="accountNumber"
                    v-model="itemLocal.accountNumber"
                    placeholder="001788574"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="identificationNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Giấy tờ tùy thân (CCCD/CMND/Hộ chiếu)
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="identificationNumber"
                    v-model="itemLocal.identificationNumber"
                    placeholder="03897546897564"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="mobilePhone"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Số điện thoại
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="mobilePhone"
                    v-model="itemLocal.mobilePhone"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Email
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder="phongnn@resident.vn"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantName"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Tên merchant (Tiếng Việt không dấu)
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantName"
                    v-model="itemLocal.merchantName"
                    placeholder="CCMN Resident"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantAddress"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Địa chỉ merchant (Tiếng Việt không dấu)
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantAddress"
                    v-model="itemLocal.merchantAddress"
                    placeholder="91 Nguyen Chi Thanh"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="step===2">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  3. Xác thực mã OTP
                </div>
              </div>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="otpCode"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Mã OTP
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="otpCode"
                    v-model="itemLocal.otpCode"
                    placeholder="668866"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </div>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow, BCol, BAlert, BImg,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useTingeeOcbModal from './useTingeeOcbModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BImg,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      step,
      onSubmit,
    } = useTingeeOcbModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      step,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
