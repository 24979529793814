<template>
  <div>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner
        class="text-center"
        variant="secondary"
      />
    </div>
    <div v-else>
      <b-button v-b-modal.modal-tingee-ocb>
        Thêm tài khoản
      </b-button>
      <account-table
        v-if="items.length > 0"
        :data="items"
      />

    </div>
    <tingee-ocb-modal :item="{}" />

  </div>
</template>

<script>
import {
  BButton, BSpinner,
} from 'bootstrap-vue';
import formValidation from '@core/comp-functions/forms/form-validation';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AccountTable from './AccountTable.vue';
import TingeeOcbModal from './TingeeOcbModal.vue';

export default {
  components: {
    BButton, AccountTable, BSpinner, TingeeOcbModal,
  },
  data() {
    return {
      isLoading: false,
      hasOCBAccount: true,
      items: [],
      ocbInfo: {
        customerName: '',
        mobilePhone: '',
        email: '',
        accountNumber: '',
        merchantName: '',
        merchantAddress: '',
        identificationNumber: '',
      },
      step: 1,
      otpCode: '',
      ocbResponse: {
        bankRefNo: '',
        clientTransId: '',
      },
    };
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    return {
      getValidationState, refFormObserver, resetForm, clearForm,
    };
  },
  created() {
    this.fetchOCBAccount();
  },
  methods: {
    hasOCBAccountChanged(newVal) {
      this.hasOCBAccount = newVal;
    },
    fetchOCBAccount() {
      this.isLoading = true;
      useJwt.tingeeGetOCBAccount().then(response => {
        this.items = response.data;
        this.isLoading = false;
      });
    },
    onSubmit() {
      this.isLoading = true;
      if (this.step === 1) {
        useJwt.tingeeVerifyOCBInfo(this.ocbInfo).then(response => {
          this.isLoading = false;
          this.ocbResponse = response.data;
          this.step = 2;
        }).catch(() => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              text: 'Bạn ơi đợi chút nhé, Resident đang cập nhật hệ thống trong vòng 1 phút . Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
      } else {
        this.isLoading = true;
        useJwt.tingeeVerifyOCBInfoWithOtp({ otpCode: this.otpCode, bankRefNo: this.ocbResponse.bankRefNo }).then(() => {
          this.step = 2;
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              text: 'Bạn ơi đợi chút nhé, Resident đang cập nhật hệ thống trong vòng 1 phút . Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
