<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col>
          <image-view
            v-model="data.brandLogoFile"
            :image-src="data.brandLogo"
            label="Logo thương hiệu"
            :src="data.brandLogo"
          />
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="12">
          <span>Mở tính năng ký túc xá</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.enableBed"
              name="enableBed"
              :value="true"
              class="custom-control-primary"
            >
              Bật
            </b-form-radio>

            <b-form-radio
              v-model="data.enableBed"
              name="enableBed"
              :value="false"
              class="custom-control-primary"
            >
              Tắt
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="12">
          <span>Mở tính năng quản lý khu vực</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.enableLocationModule"
              name="enableLocationModule"
              :value="true"
              class="custom-control-primary"
            >
              Bật
            </b-form-radio>

            <b-form-radio
              v-model="data.enableLocationModule"
              name="enableLocationModule"
              :value="false"
              class="custom-control-primary"
            >
              Tắt
            </b-form-radio>
          </div>
        </b-col>
      </b-row>

      <!-- <b-row class="pb-2">
        <b-col cols="12">
          <span>Loại bỏ giảm giá trong phân bổ doanh thu chi phí</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.ignoreDiscountAllocation"
              name="ignoreDiscountAllocation"
              :value="true"
              class="custom-control-primary"
            >
              Có
            </b-form-radio>

            <b-form-radio
              v-model="data.ignoreDiscountAllocation"
              name="ignoreDiscountAllocation"
              :value="false"
              class="custom-control-primary"
            >
              Không
            </b-form-radio>
          </div>
        </b-col>
      </b-row> -->

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        Lưu
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BFormRadio,
} from 'bootstrap-vue';
import { required, between } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ImageView from '@/views/components/ImageView.vue';
import useJwt from '@/auth/jwt/useJwt';
import { ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { updateUserConfiguration } from '@/auth/utils';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ImageView,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        brandLogo: '',
        brandLogoFile: null,
        autoApproveMeterLog: false,
        enableBed: false,
        enableLocationModule: false,
        // ignoreDiscountAllocation: false,
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getUserConfiguration()
        .then(response => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      if (this.data.brandLogoFile) {
        data.append('brandLogo', this.data.brandLogoFile);
      }
      data.append('autoApproveMeterLog', this.data.autoApproveMeterLog);
      data.append('enableBed', this.data.enableBed);
      data.append('enableLocationModule', this.data.enableLocationModule);
      // data.append('ignoreDiscountAllocation', this.data.ignoreDiscountAllocation);

      useJwt.updateUserConfiguration(data).then(response => {
        updateUserConfiguration(response.data);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'UserIcon',
            variant: 'success',
            text: `Thông tin đã được cập nhật thành công`,
          },
        });
      })
        .catch(error => {
          if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          }
        });
    },
  },
};
</script>
