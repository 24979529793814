<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Cài đặt</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-tabs>
        <b-tab>
          <template #title>
            <feather-icon icon="SettingsIcon" />
            <span>Cài đặt chung</span>
          </template>

          <setting-tab-general />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="EditIcon" />
            <span>Hợp đồng</span>
          </template>

          <setting-tab-contract />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Hóa đơn</span>
          </template>

          <setting-tab-invoice />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FolderPlusIcon" />
            <span>Thu chi</span>
          </template>

          <setting-tab-income-expense />
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>Email(SMTP)</span>
          </template>
          <setting-tab-email />
        </b-tab> -->
        <b-tab>
          <template #title>
            <feather-icon icon="CreditCardIcon" />
            <span>Kết nối ngân hàng</span>
          </template>
          <setting-tab-tingee />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ShoppingBagIcon" />
            <span>Sale phòng</span>
          </template>
          <setting-tab-sale />
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTab, BTabs, BCardTitle, BCardHeader,
} from 'bootstrap-vue';
import SettingTabGeneral from './tabs/SettingTabGeneral.vue';
import SettingTabSale from './tabs/SettingTabSale.vue';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import SettingTabTingee from './tabs/SettingTabTingee.vue';
import SettingTabContract from './tabs/SettingTabContract.vue';
import SettingTabInvoice from './tabs/SettingTabInvoice.vue';
import SettingTabIncomeExpense from './tabs/SettingTabIncomeExpense.vue';

export default {
  components: {
    BCard,
    BCardBody,
    BTab,
    BTabs,
    BCardTitle,
    BCardHeader,
    SettingTabGeneral,
    SettingTabSale,
    SettingTabTingee,
    SettingTabContract,
    SettingTabInvoice,
    SettingTabIncomeExpense,
  },
  setup() {

  },
};
</script>
